import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import AllocationDay from "./AllocationDay";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import {
  ISchedule,
  ScheduleDetails,
  ScheduleEntry,
} from "../../../../../types/nurse-manager-interfaces/IMonthCalendar.interface";
import {
  IDraft,
  DraftScheduleDetails,
  DraftSchedule,
} from "../../../../../types/nurse-manager-interfaces/IDraft.interface";
import {
  MonthSelected,
  YearSelected,
} from "../../../../../types/nurse-manager-interfaces/IMonthYear.interface";

interface DayInfo {
  date: string; // Assuming date is in ISO format
  allocation: ScheduleEntry;
  isCurrentMonth: boolean;
}
interface AllocationData {
  date: string | number;
  shiftType: string;
  leaderValue: any;
  nurseValue: any;
  supervisorValue: string | number;
  isAllocationFilled: boolean;
}
interface Props {
  scheduleData: ScheduleDetails;
  setDateSelected: (data: string) => void;
  isEditingDraft: boolean;
  draftData: ScheduleDetails;
  openAllocationId: string | null;
  setOpenAllocationId: any; //(id: string | null) => void;
  selectedDate: string | null;
  setSelectedDate: (date: string | null) => void;
  month: MonthSelected;
  year: YearSelected;
}
const MonthCalendar: React.FC<Props> = ({
  scheduleData,
  setDateSelected,
  isEditingDraft,
  draftData,
  openAllocationId,
  setOpenAllocationId,
  selectedDate,
  setSelectedDate,
  month,
  year,
}) => {
  const [dataCalendar, setDataCalendar] = useState<
    ScheduleEntry[] | DraftSchedule[]
  >([] as ScheduleEntry[]);

  useEffect(() => {
    if (scheduleData && !isEditingDraft) {
      setDataCalendar(scheduleData?.Schedule);
    } else if (draftData && isEditingDraft) {
      setDataCalendar(draftData?.Schedule);
    }
    setDateSelected("");
  }, [scheduleData, draftData, isEditingDraft]);
  useEffect(() => {
    if (isEditingDraft) setDataCalendar([] as ScheduleEntry[]);
    setDateSelected("");
    setSelectedDate(null);
    setOpenAllocationId(null);
  }, [isEditingDraft]);

  const generateCalendar = (
    year: number,
    month: number,
    allocationData: (ScheduleEntry | DraftSchedule)[]
  ) => {
    const calendar: any = [];
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const daysInMonth = lastDay.getDate();
    let startDayOfWeek = firstDay.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

    // Adjust startDayOfWeek to consider Monday as the first day
    startDayOfWeek = startDayOfWeek === 0 ? 6 : startDayOfWeek - 1;

    let currentDate = new Date(firstDay);

    // Move back to the previous Monday if the month doesn't start on a Monday
    currentDate.setDate(currentDate.getDate() - startDayOfWeek);

    while (currentDate <= lastDay) {
      const week: any = [];

      for (let i = 0; i < 7; i++) {
        const date = new Date(currentDate);
        const isCurrentMonth = date.getMonth() === month;

        // Find allocation data for the current date
        const formattedDate = moment(date).format("YYYY-MM-DD");
        const allocation = allocationData?.find((data) => {
          if (moment(data.date).format("YYYY-MM-DD") === formattedDate) {
            return data;
          } else {
            return undefined;
          }
        });

        week.push({
          date,
          isCurrentMonth,
          allocation,
        });
        currentDate.setDate(currentDate.getDate() + 1);
      }

      calendar.push(week);
    }

    return calendar;
  };
  const { selectedShift } = useSelector((state: RootState) => state.time);
  const calendarData = generateCalendar(
    +year?.year,
    month?.label - 1,
    dataCalendar
  );

  const handleToggleDetails = (dayWeekIndex: string) => {
    if (openAllocationId?.includes(dayWeekIndex)) return;
    setOpenAllocationId((prev: string) =>
      prev === dayWeekIndex ? null : dayWeekIndex
    );
  };
  return (
    <>
      {/* <SimpleBar
        className="overflow-y-auto"
        style={{ height: "calc(100vh - 440px)" }}
      >
        <div className="flex flex-wrap">
          {generateCalendar(2023, 2).map((data: any, index: any) => (
            <div
              key={index}
              className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white"
            >
              <AllocationDay
                date={data.date}
                shiftType={data.shiftType}
                leaderValue={data.leaderValue}
                nurseValue={data.nurseValue}
                supervisorValue={data.supervisorValue}
                isAllocationFilled={data.isAllocationFilled}
              />
            </div>
          ))}
        </div>
      </SimpleBar> */}
      <SimpleBar
        className="overflow-y-auto scheduler"
        style={{ height: "calc(100vh - 362px)" }}
      >
        {calendarData.map((week: any, weekIndex: number) => (
          <div key={weekIndex} className="flex flex-wrap flex-1">
            {week?.map(
              (
                dayInfo: DayInfo,
                dayIndex: number // dayInfo==> allocation :{} //object //date//isCurrentMonth
              ) => (
                <div
                  key={dayIndex}
                  className={`basis-1/7 h-full p-[12px] border border-beerus bg-white ${
                    dataCalendar && dayInfo.isCurrentMonth
                      ? ""
                      : "pointer-events-none"
                  } ${
                    moment(dayInfo?.date).isSame(selectedDate, "day")
                      ? "border-radius-[4px] border-cyan-300"
                      : ""
                  }`}
                  onClick={() => {
                    setDateSelected(dayInfo?.date);
                    setSelectedDate(dayInfo?.date); // Update selected date
                  }}
                >
                  <AllocationDay
                    isCurrentMonth={
                      dataCalendar ? dayInfo?.isCurrentMonth : false
                    }
                    date={moment(dayInfo?.date).format("DD")}
                    shiftType={selectedShift} //data?.shiftType
                    seniorNurseCount={
                      selectedShift === "sun"
                        ? dayInfo?.allocation?.dayShiftSeniorNurses?.length
                        : dayInfo?.allocation?.nightShiftSeniorNurses?.length
                    } //data?.shiftType
                    nurseCount={
                      selectedShift === "sun"
                        ? dayInfo?.allocation?.dayShiftNurses?.length
                        : dayInfo?.allocation?.nightShiftNurses?.length
                    }
                    hcaCount={
                      selectedShift === "sun"
                        ? dayInfo?.allocation?.dayShiftHCAs?.length
                        : dayInfo?.allocation?.nightShiftHCAs?.length
                    }
                    isAllocationFilled={
                      selectedShift == "sun"
                        ? dayInfo?.allocation?.balancedHCAsForDayShift &&
                          dayInfo?.allocation?.balancedNursesForDayShift &&
                          dayInfo?.allocation?.balancedSeniorNursesForDayShift
                        : dayInfo?.allocation?.balancedHCAsForNightShift &&
                          dayInfo?.allocation?.balancedNursesForNightShift &&
                          dayInfo?.allocation?.balancedSeniorNursesForNightShift
                    }
                    isOverUtilized={
                      selectedShift == "sun"
                        ? dayInfo?.allocation?.dayShiftOverUtilization
                        : dayInfo?.allocation?.nightShiftOverUtilization
                    }
                    onToggleDetails={() =>
                      handleToggleDetails(`${weekIndex}-${dayIndex}`)
                    }
                    isULVisible={
                      selectedDate !== null &&
                      openAllocationId === `${weekIndex}-${dayIndex}`
                    }
                    // emptySchedule={dataCalendar}
                  />
                </div>
              )
            )}
          </div>
        ))}
      </SimpleBar>
    </>
  );
};
export default MonthCalendar;
